/* eslint-disable no-unused-vars */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

import { isUserAuthenticated, getLoggedInUser } from './helpers/authUtils';

import SendSms from './pages/marketing/create/sms';
import toast from './helpers/toast';
import clientsSelectionV2 from './pages/marketing/clientsSelectionV2';
// lazy load all the views
const Dashboard = React.lazy(() => import('./pages/Dashboard'));

// auth
const Login = React.lazy(() => import('./pages/auth/Login'));
const Logout = React.lazy(() => import('./pages/auth/Logout'));
const ForgetPassword = React.lazy(() =>
  import('./pages/account/ForgetPassword')
);
const Register = React.lazy(() => import('./pages/account/Register'));
const ConfirmAccount = React.lazy(() => import('./pages/account/Confirm'));

//#region Menu Empresas
const ListarLoja = React.lazy(() => import('./pages/Store/Listar'));
const CadastrarLoja = React.lazy(() => import('./pages/Store/Cadastrar'));
const listGasStation = React.lazy(() => import('./pages/gasstation/List'));
const createGasStation = React.lazy(() => import('./pages/gasstation/Create'));
//#endregion

//#region Menu Cadastros
const listBanners = React.lazy(() => import('./pages/banner/list'));
const bannerCadastrar = React.lazy(() => import('./pages/banner/create'));
const crateCategory = React.lazy(() => import('./pages/Category/Cadastrar'));
const listCategory = React.lazy(() => import('./pages/Category/Listar'));
const produto = React.lazy(() => import('./pages/Products/Listar'));
const produtoCadastrar = React.lazy(() => import('./pages/Products/Cadastrar'));
const produtogas = React.lazy(() => import('./pages/ProductsGas/Listar'));
const listQuickAccess = React.lazy(() => import('./pages/QuickAccess/List'));
const createQuickAccess = React.lazy(() =>
  import('./pages/QuickAccess/Create')
);

const produtogasCadastrar = React.lazy(() =>
  import('./pages/ProductsGas/Cadastrar')
);
const productGasGroupList = React.lazy(() =>
  import('./pages/productsGasGroup/list')
);
const createProductGasGroup = React.lazy(() =>
  import('./pages/productsGasGroup/create')
);
//#endregion

//#region Menu Clientes
const Clients = React.lazy(() => import('./pages/clients'));
const ClientDashboard = React.lazy(() => import('./pages/clients/dashboard'));
const ClientProfiles = React.lazy(() => import('./pages/clientProfile'));
const CreateClientProfile = React.lazy(() =>
  import('./pages/clientProfile/create')
);
//#endregion

//#region Menu Gamificação
const listGamificationLevels = React.lazy(() =>
  import('./pages/Gamification/Levels/List')
);
const createGamificationLevels = React.lazy(() =>
  import('./pages/Gamification/Levels/Create')
);

//#endregion

//#region Menu Regras
const listPrice = React.lazy(() => import('./pages/price/list'));
const createPrice = React.lazy(() => import('./pages/price/create'));
const editPriceCashback = React.lazy(() =>
  import('./pages/price/editCashback')
);
const CreatePromotion = React.lazy(() =>
  import('./pages/promotions/multiplas/create/index.jsx')
);
const MultiplaPromotion = React.lazy(() =>
  import('./pages/promotions/multiplas/index.jsx')
);
const createPromotionsByProductgas = React.lazy(() =>
  import('./pages/promotions/createByProductgas')
);
const createPromotionsByProductCategory = React.lazy(() =>
  import('./pages/promotions/createByProductCategory')
);
const createPromotionsByService = React.lazy(() =>
  import('./pages/promotions/createByService')
);
const listPromotions = React.lazy(() => import('./pages/promotions/list'));
const editPromotions = React.lazy(() => import('./pages/promotions/edite'));

const listPaymentsType = React.lazy(() =>
  import('./pages/customPayments/list')
);
const createPaymentsType = React.lazy(() =>
  import('./pages/customPayments/create')
);

//#endregion

//#region Menu Consultas
const BuscarVouchers = React.lazy(() => import('./pages/queries/Vouchers'));
const ConsultaAbastecimentos = React.lazy(() =>
  import('./pages/queries/Abastecimentos')
);
//#endregion

//#region Menu Atendentes
const listAtendentes = React.lazy(() => import('./pages/attendant/index'));
const createAtendentes = React.lazy(() => import('./pages/attendant/create'));
const dashboardAtendentes = React.lazy(() =>
  import('./pages/attendant/dashboard')
);
const listTurnos = React.lazy(() => import('./pages/rotation/list'));
const createTurno = React.lazy(() => import('./pages/rotation/create'));
const alterarSenhaAtendente = React.lazy(() =>
  import('./pages/attendant/changePassword')
);
const listParametrosComissoes = React.lazy(() =>
  import('./pages/commissionParameter/list')
);
const createParametroComissao = React.lazy(() =>
  import('./pages/commissionParameter/create')
);
const calculateComissao = React.lazy(() =>
  import('./pages/commission/calculateCommission')
);
const listComissoes = React.lazy(() => import('./pages/commission/list'));
const listMetas = React.lazy(() => import('./pages/goal/list'));
const createMeta = React.lazy(() => import('./pages/goal/create'));
const listFaixaMetas = React.lazy(() => import('./pages/goalRange/list/index'));
const createFaixaMeta = React.lazy(() =>
  import('./pages/goalRange/create/index')
);
//#endregion

//#region Menu Convênios
const listConvenios = React.lazy(() => import('./pages/Agreement/list'));
const cadastrarConvenio = React.lazy(() => import('./pages/Agreement/create'));
const listRegrasConvenio = React.lazy(() =>
  import('./pages/agreementRules/list')
);
const createRegraConvenio = React.lazy(() =>
  import('./pages/agreementRules/create')
);
const listConveniado = React.lazy(() => import('./pages/Agreed/list'));
const createConveniado = React.lazy(() => import('./pages/Agreed/create'));
//#endregion

//#region Menu Frotistas
const listFrostistas = React.lazy(() => import('./pages/fleetOwner/list'));
const cadastrarFrotista = React.lazy(() => import('./pages/fleetOwner/create'));
const listFleetDriver = React.lazy(() => import('./pages/fleetDriver/list'));
const createFleetDriver = React.lazy(() =>
  import('./pages/fleetDriver/create')
);
const createNfc = React.lazy(() => import('./pages/nfc/create'));
const listNfc = React.lazy(() => import('./pages/nfc/list'));
const listFleetVehicle = React.lazy(() => import('./pages/fleetVehicle/list'));
const createFleetVehicle = React.lazy(() =>
  import('./pages/fleetVehicle/create')
);
const createFleetOwnerRule = React.lazy(() =>
  import('./pages/fleetOwnerRules/create')
);
const listFleetOwnerRule = React.lazy(() =>
  import('./pages/fleetOwnerRules/list')
);
//#endregion

//#region Menu Frotistas
const cashbackReport = React.lazy(() =>
  import('./pages/financial/cashbackReport')
);
const createCashbackReport = React.lazy(() =>
  import('./pages/financial/cashbackReport/create')
);
//#endregion

//#region Menu Marketing
const listNotification = React.lazy(() => import('./pages/notification/List'));
const listNotificationV2 = React.lazy(() =>
  import('./pages/notification/ListV2')
);
const createNotification = React.lazy(() =>
  import('./pages/notification/Create')
);
const createNotificationV2 = React.lazy(() =>
  import('./pages/notification/CreateV2')
);
const clientsSelection = React.lazy(() =>
  import('./pages/marketing/clientsSelection')
);
const createSms = React.lazy(() => import('./pages/marketing/create/sms'));
//#endregion

//#region Menu Relatórios
const reportFill = React.lazy(() => import('./pages/Reports/fillGas'));
const reportEvaluetions = React.lazy(() =>
  import('./pages/Reports/evaluetions')
);
const clientsSubscribedInClubeCerto = React.lazy(() =>
  import('./pages/Reports/ClientsSubscribedClubeCerto')
);
const reportVouchersAcumulados = React.lazy(() =>
  import('./pages/Reports/vouchersAcumulados')
);
const reportAgreements = React.lazy(() => import('./pages/Reports/agreements'));
const reportVouchersResgatados = React.lazy(() =>
  import('./pages/Reports/vouchersResgatados')
);
const reportVouchersNaoUsados = React.lazy(() =>
  import('./pages/Reports/vouchersNaoUsados')
);
const reportVouchersPendentes = React.lazy(() =>
  import('./pages/Reports/vouchersPendentes')
);
const reportClients = React.lazy(() => import('./pages/Reports/clients'));
const reportEmpresas = React.lazy(() =>
  import('./pages/Reports/extratoEmpresas')
);
const ReportTimeSinceSale = React.lazy(() =>
  import('./pages/Reports/ReportTimeSinceSale')
);
const ReportClientsBalance = React.lazy(() =>
  import('./pages/Reports/ReportClientsBalance')
);
const ExtratoCliente = React.lazy(() => import('./pages/clients/extrato'));
const VeiculosCliente = React.lazy(() => import('./pages/clients/veiculo'));
const RankingClientes = React.lazy(() => import('./pages/clients/ranking'));
const reportClientsBirthday = React.lazy(() =>
  import('./pages/Reports/clientsBirthday')
);
const reportsSalesByProducts = React.lazy(() =>
  import('./pages/Reports/sales/byProducts/index')
);
const reportsSalesByPayments = React.lazy(() =>
  import('./pages/Reports/sales/byPayments/index')
);
const reportsSalesProjection = React.lazy(() =>
  import('./pages/Reports/sales/projection/index')
);
const reportCreditExternal = React.lazy(() =>
  import('./pages/Reports/creditExternal')
);
const reportCredits = React.lazy(() => import('./pages/Reports/Credits'));

const reportDecisionMaking = React.lazy(() =>
  import('./pages/Reports/decisionMaking')
);
const ReportPerformancePromotions = React.lazy(() =>
  import('./pages/Reports/promotions/performace')
);
const ReportIndicateRanking = React.lazy(() =>
  import('./pages/Reports/ReportIndicateRanking')
);
const AverageRatingAttendants = React.lazy(() =>
  import('./pages/Reports/ratingAttendants')
);
const RankingAttendants = React.lazy(() =>
  import('./pages/Reports/rankingAttendants')
);

const TransacionsByAttendant = React.lazy(() =>
  import('./pages/Reports/transacionsByAttendant')
);

//#endregion

//#region Espião
const spy = React.lazy(() => import('./pages/Spy'));
const logs = React.lazy(() => import('./pages/logsCustomer/list'));
const requestLogs = React.lazy(() => import('./pages/requestLogs'));
const priceLogs = React.lazy(() => import('./pages/priceLogs'));
//#endregion

//#region Central de Notificações
const notificationCenter = React.lazy(() =>
  import('./pages/notificationCenter')
);
//#endregion

//#region Menu Acesso
const usuario = React.lazy(() => import('./pages/Users/Listar'));
const usuarioCadastrar = React.lazy(() => import('./pages/Users/Cadastrar'));
const perfil = React.lazy(() => import('./pages/perfil'));
//#endregion

//#region  Menu superior
const redes = React.lazy(() => import('./pages/customer/Listar'));
const redeCadastrar = React.lazy(() => import('./pages/customer/Cadastrar'));
const listDistribuidores = React.lazy(() => import('./pages/distributor/list'));
const createDistribuidores = React.lazy(() =>
  import('./pages/distributor/create')
);
const listAcumuloManual = React.lazy(() =>
  import('./pages/manualCashback/list')
);

const listEstornoResgate = React.lazy(() =>
  import('./pages/refundResgate/list')
);

const createAcumuloManual = React.lazy(() =>
  import('./pages/manualCashback/create')
);
const AccessKeys = React.lazy(() => import('./pages/AccessKeys'));
//#endregion

//#region Menu Configurações
const configurations = React.lazy(() => import('./pages/parameters/index'));
const birthdayNotification = React.lazy(() =>
  import('./pages/parameters/birthday/birthdayNotification')
);

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      window.scrollTo(0, 0);

      const isAuthTokenValid = isUserAuthenticated();
      if (!isAuthTokenValid) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );
      }
      const loggedInUser = getLoggedInUser();
      // check if route is restricted by role

      if (roles && roles.indexOf(loggedInUser.role) === -1) {
        toast.warn('Você não tem permissão para acessar esta página');
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: '/' }} />;
      }

      // authorised so return component
      return <Component {...props} />;
    }}
  />
);

const routes = [
  // auth and account
  {
    path: '/login',
    name: 'Login',
    component: Login,
    route: Route,
    exact: true
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    route: Route,
    exact: true
  },
  {
    path: '/forget-password',
    name: 'Forget Password',
    component: ForgetPassword,
    route: Route,
    exact: true
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    route: Route,
    exact: true
  },
  {
    path: '/confirm',
    name: 'Confirm',
    component: ConfirmAccount,
    route: Route,
    exact: true
  },

  // other pages
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor', 'reports'],
    title: 'Dashboard',
    exact: true
  },
  {
    // path: '/lojas',
    path: '/empresas/lojas',
    name: 'Lojas',
    component: ListarLoja,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Lojas',
    exact: true
  },
  {
    // path: '/lojas/cadastrar',
    path: '/empresas/lojas/cadastrar',
    name: 'Cadastrar Loja',
    component: CadastrarLoja,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Cadastrar Loja',
    exact: true
  },
  {
    // path: '/lojas/editar/:id',
    path: '/empresas/lojas/editar/:id',
    name: 'Editar Loja',
    component: CadastrarLoja,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Editar Loja',
    exact: true
  },
  {
    path: '/acesso/minha-conta',
    name: 'Perfil',
    component: perfil,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Perfil',
    exact: true
  },
  {
    //path: '/convenios/cadastrar',
    path: '/convenios/cadastrar',
    name: 'Cadastrar Convênio',
    component: cadastrarConvenio,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Cadastrar Convênio',
    exact: true
  },
  {
    //path: '/convenios/cadastrar',
    path: '/convenios/editar/:id',
    name: 'Cadastrar Convênio',
    component: cadastrarConvenio,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Cadastrar Convênio',
    exact: true
  },
  // {
  //   path: '/frotistas',
  //   name: 'Frotistas',
  //   component: listFrostistas,
  //   route: PrivateRoute,
  //   roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
  //   title: 'Frotistas',
  //   exact: true
  // },
  {
    path: '/frotistas/cadastrar',
    name: 'Cadastrar Frotista',
    component: cadastrarFrotista,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Cadastrar Frotista',
    exact: true
  },
  {
    path: '/frotistas/editar/:id',
    name: 'Cadastrar Frotista',
    component: cadastrarFrotista,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Cadastrar Frotista',
    exact: true
  },
  {
    //path: '/convenios',
    path: '/convenios',
    name: 'Convênios',
    component: listConvenios,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Convênios',
    exact: true
  },
  {
    path: '/convenios/regras',
    name: 'Regras de Convênios',
    component: listRegrasConvenio,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Regras de Convênios',
    exact: true
  },
  {
    path: '/convenios/regras/cadastrar',
    name: 'Cadastrar Regra de Convênios',
    component: createRegraConvenio,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Cadastrar Regra de Convênios',
    exact: true
  },
  {
    path: '/convenios/regras/editar/:id',
    name: 'Editar Regra de Convênios',
    component: createRegraConvenio,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Editar Regra de Convênios',
    exact: true
  },
  {
    path: '/convenios/conveniados/cadastrar',
    name: 'Cadastrar Conveniado',
    component: createConveniado,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Cadastrar Conveniado',
    exact: true
  },
  {
    path: '/convenios/conveniados/editar/:id',
    name: 'Editar Conveniado',
    component: createConveniado,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Editar Conveniado',
    exact: true
  },
  {
    path: '/convenios/conveniados',
    name: 'Conveniados',
    component: listConveniado,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Conveniados',
    exact: true
  },
  {
    path: '/frotistas/motoristas/cadastrar',
    name: 'Cadastrar Motorista',
    component: createFleetDriver,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Cadastrar Motorista',
    exact: true
  },
  {
    path: '/frotistas/motoristas/editar/:id',
    name: 'Editar Motorista',
    component: createFleetDriver,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Editar Motorista',
    exact: true
  },
  {
    path: '/frotistas/motoristas/',
    name: 'Motoristas',
    component: listFleetDriver,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Motoristas',
    exact: true
  },
  {
    path: '/frotistas/nfcs/cadastrar',
    name: 'Cadastrar NFC',
    component: createNfc,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Cadastrar NFC',
    exact: true
  },
  {
    path: '/frotistas/nfcs/editar/:id',
    name: 'Editar NFC',
    component: createNfc,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Editar NFC',
    exact: true
  },
  {
    path: '/frotistas/nfcs/',
    name: 'NFCs',
    component: listNfc,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'NFCs',
    exact: true
  },

  {
    path: '/frotistas/veiculos/cadastrar',
    name: 'Cadastrar Veículo',
    component: createFleetVehicle,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Cadastrar Veículo',
    exact: true
  },
  {
    path: '/frotistas/veiculos/editar/:id',
    name: 'Editar Veículo',
    component: createFleetVehicle,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Editar Veículo',
    exact: true
  },
  {
    path: '/frotistas/veiculos',
    name: 'Veículos',
    component: listFleetVehicle,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Veículos',
    exact: true
  },
  {
    path: '/frotistas/regras/cadastrar',
    name: 'Cadastrar Regra de Frotista',
    component: createFleetOwnerRule,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Cadastrar Regra de Frotista',
    exact: true
  },
  {
    path: '/frotistas/regras/editar/:id',
    name: 'Editar Regra de Frotista',
    component: createFleetOwnerRule,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Editar Regra de Frotista',
    exact: true
  },
  {
    path: '/frotistas/regras',
    name: 'Regras de Frotistas',
    component: listFleetOwnerRule,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distribuidor'],
    title: 'Regras de Frotistas',
    exact: true
  },

  // {
  //   path: '/listartrocadeoleo',
  //   name: 'ListarTrocaDeOleo',
  //   component: ListarTrocaDeOleo,
  //   route: PrivateRoute,
  //   roles: ['Admin', 'customer'],
  //   title: 'Listar Troca de Óleo',
  // },
  // {
  //   path: '/cadastrartrocadeoleo',
  //   name: 'CadastrarTrocaDeOleo',
  //   component: CadastrarTrocaDeOleo,
  //   route: PrivateRoute,
  //   roles: ['Admin', 'customer'],
  //   title: 'Cadastrar Troca de Óleo',
  // },
  {
    // path: '/usuarios',
    path: '/acesso/usuarios',
    name: 'Usuário',
    component: usuario,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'empresas'],
    title: 'Usuário',
    exact: true
  },
  {
    // path: '/usuarios/cadastrar',
    path: '/acesso/usuarios/cadastrar',
    name: 'Cadastrar Usuário',
    component: usuarioCadastrar,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'empresas'],
    title: 'Cadastrar Usuário',
    exact: true
  },
  {
    // path: '/usuarios/editar/:id',
    path: '/acesso/usuarios/editar/:id',
    name: 'Editar Usuário',
    component: usuarioCadastrar,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'empresas'],
    title: 'Editar Usuário',
    exact: true
  },
  {
    path: '/clientes-cibus',
    name: 'Clientes Cibus',
    component: redes,
    route: PrivateRoute,
    roles: ['Admin', 'distributor'],
    title: 'Clientes Cibus',
    exact: true
  },
  {
    path: '/clientes-cibus/cadastrar',
    name: 'Cadastrar Cliente Cibus',
    component: redeCadastrar,
    route: PrivateRoute,
    roles: ['Admin', 'distributor'],
    title: 'Cadastrar Cliente Cibus',
    exact: true
  },
  {
    path: '/clientes-cibus/editar/:id',
    name: 'Editar Cliente Cibus',
    component: redeCadastrar,
    route: PrivateRoute,
    roles: ['Admin', 'distributor', 'customer'],
    title: 'Editar Cliente Cibus',
    exact: true
  },
  {
    // path: '/produtos',
    path: '/cadastros/produtos',
    name: 'Produtos',
    component: produto,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Produtos',
    exact: true
  },
  {
    // path: '/produtos/cadastrar',
    path: '/cadastros/produtos/cadastrar',
    name: 'Cadastrar Produto',
    component: produtoCadastrar,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Cadastrar Produto',
    exact: true
  },
  {
    // path: '/produtos/editar/:id',
    path: '/cadastros/produtos/editar/:id',
    name: 'Editar Produtos',
    component: produtoCadastrar,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Editar Produto',
    exact: true
  },
  {
    // path: '/tipos-de-pagamentos',
    path: '/cadastros/tipos-de-pagamentos',
    name: 'Tipos de Pagamentos',
    component: listPaymentsType,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Tipos de Pagamentos',
    exact: true
  },
  {
    path: '/cadastros/tipos-de-pagamentos/cadastrar',
    name: 'Cadastrar Tipos de Pagamentos',
    component: createPaymentsType,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Cadastrar Tipos de Pagamentos',
    exact: true
  },
  {
    // path: '/tipos-de-pagamentos',
    path: '/cadastros/tipos-de-pagamentos/editar/:id',
    name: 'Editar Tipos de Pagamentos',
    component: createPaymentsType,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Editar Tipos de Pagamentos',
    exact: true
  },
  {
    // path: '/combustiveis',
    path: '/cadastros/combustiveis',
    name: 'Combustíveis',
    component: produtogas,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Combustíveis',
    exact: true
  },
  {
    // path: '/combustiveis/cadastrar',
    path: '/cadastros/combustiveis/cadastrar',
    name: 'Cadastrar Combustível',
    component: produtogasCadastrar,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Cadastrar Combustível',
    exact: true
  },
  {
    // path: '/combustiveis/editar/:id',
    path: '/cadastros/combustiveis/editar/:id',
    name: 'Editar Combustíveis',
    component: produtogasCadastrar,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Editar Combustíveis',
    exact: true
  },
  {
    path: '/cadastros/grupos-de-combustiveis',
    name: 'Grupos de Combustíveis',
    component: productGasGroupList,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Grupos de Combustíveis',
    exact: true
  },
  {
    path: '/cadastros/grupos-de-combustiveis/cadastrar',
    name: 'Cadastrar Grupo de Combustíveis',
    component: createProductGasGroup,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Cadastrar Grupo de Combustíveis',
    exact: true
  },
  {
    path: '/cadastros/grupos-de-combustiveis/editar/:id',
    name: 'Editar Grupo de Combustíveis',
    component: createProductGasGroup,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Editar Grupo de Combustíveis',
    exact: true
  },
  {
    // path: '/categorias/cadastrar',
    path: '/cadastros/categorias/cadastrar',
    name: 'Cadastrar Categoria',
    component: crateCategory,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Cadastrar Categoria',
    exact: true
  },
  {
    // path: '/categorias',
    path: '/cadastros/categorias',
    name: 'Categoria',
    component: listCategory,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Categorias',
    exact: true
  },
  {
    // path: '/categorias/editar/:id',
    path: '/cadastros/categorias/editar/:id',
    name: 'Editar Categoria',
    component: crateCategory,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Editar Categoria',
    exact: true
  },
  {
    // path: '/cadastros/acesso-rapido',
    path: '/cadastros/acesso-rapido',
    name: 'Acesso Rápido',
    component: listQuickAccess,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Acesso Rápido',
    exact: true
  },
  {
    // path: '/cadastros/acesso-rapido/cadastrar',
    path: '/cadastros/acesso-rapido/cadastrar',
    name: 'Cadastro de Acesso Rápido',
    component: createQuickAccess,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Cadastro de Acesso Rápido',
    exact: true
  },
  {
    // path: '/banners/editar/:id',
    path: '/cadastros/acesso-rapido/editar/:id',
    name: 'Editar Acesso Rápido',
    component: createQuickAccess,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Editar Acesso Rápido',
    exact: true
  },
  {
    // path: '/notificacoes',
    path: '/financeiro/acerto-de-resgate',
    name: 'Acerto de Resgate',
    component: cashbackReport,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Acerto de Resgate',
    exact: true
  },
  {
    // path: '/notificacoes',
    path: '/financeiro/acerto-de-resgate/calcular',
    name: 'Calcular Acerto de Resgate',
    component: createCashbackReport,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Calcular Acerto de Resgate',
    exact: true
  },
  //{
  // path: '/notificacoes',
  // path: '/marketing/notificacoes',
  // name: 'Notificações',
  // component: listNotification,
  // route: PrivateRoute,
  // roles: ['Admin', 'customer', 'distributor'],
  // title: 'Notificações',
  // exact: true,
  //},
  {
    // path: '/notificacoes',
    path: '/marketing/notificacoes-V2',
    name: 'Notificações',
    component: listNotificationV2,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Notificações',
    exact: true
  },
  // {
  //   // path: '/notificacoes/enviar',
  //   path: '/marketing/notificacoes/selecao-de-clientes',
  //   name: 'Seleção de Clientes',
  //   component: clientsSelection,
  //   route: PrivateRoute,
  //   roles: ['Admin', 'customer', 'distributor'],
  //   title: 'Seleção de Clientes',
  //   exact: true,
  // },
  {
    // path: '/notificacoes/enviar',
    path: '/marketing/notificacoes/selecao-de-clientes-V2',
    name: 'Seleção de Clientes V2',
    component: clientsSelectionV2,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Seleção de Clientes',
    exact: true
  },
  // {
  //   // path: '/notificacoes/enviar',
  //   path: '/marketing/notificacoes/selecao-de-clientes/enviar/:id',
  //   name: 'Enviar Notificação',
  //   component: createNotification,
  //   route: PrivateRoute,
  //   roles: ['Admin', 'customer', 'distributor'],
  //   title: 'Enviar Notificação',
  //   exact: true,
  // },
  {
    // path: '/notificacoes/enviar',
    path: '/marketing/notificacoes/selecao-de-clientes-V2/enviar',
    name: 'Enviar Notificação V2',
    component: createNotificationV2,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Enviar Notificação V2',
    exact: true
  },
  {
    // path: '/postos',
    path: '/empresas/postos',
    name: 'Postos',
    component: listGasStation,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Postos',
    exact: true
  },
  {
    // path: '/postos/cadastrar',
    path: '/empresas/postos/cadastrar',
    name: 'Cadastrar Posto',
    component: createGasStation,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Cadastrar Posto',
    exact: true
  },
  {
    // path: '/postos/editar/:id',
    path: '/empresas/postos/editar/:id',
    name: 'Editar Posto',
    component: createGasStation,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Editar Posto',
    exact: true
  },
  {
    // path: '/precos',
    path: '/regras/precos',
    name: 'Preços',
    component: listPrice,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Preços',
    exact: true
  },
  {
    // path: '/precos/editar/:id',
    path: '/regras/precos/editar/:id',
    name: 'Editar Preço',
    component: createPrice,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Editar Preço',
    exact: true
  },
  {
    // path: '/precos/cadastrar',
    path: '/regras/precos/cadastrar',
    name: 'Cadastrar Preço',
    component: createPrice,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Cadastrar Preço',
    exact: true
  },
  {
    path: '/regras/precos/ajustar-acumulos/:id',
    name: 'Ajustar Acúmulos',
    component: editPriceCashback,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Ajustar Acúmulos',
    exact: true
  },
  {
    // path: '/promocoes/cadastrar',
    path: '/regras/multiplasPromocoes/cadastrar',
    name: 'Cadastrar Promoção',
    component: CreatePromotion,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Cadastrar Promoção',
    exact: true
  },
  {
    // path: '/promocoes/cadastrar',
    path: '/regras/multiplasPromocoes/editar/:id',
    name: 'Editar Promoção',
    component: CreatePromotion,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Cadastrar Promoção',
    exact: true
  },
  {
    // path: '/promocoes/cadastrar',
    path: '/regras/multiplasPromocoes/',
    name: 'Cadastrar Promoção',
    component: MultiplaPromotion,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Listar Promoção',
    exact: true
  },

  {
    // path: '/promocoes/cadastrar',
    path: '/regras/promocoes/cadastrar/por-combustivel',
    name: 'Cadastrar Promoção - Combustível',
    component: createPromotionsByProductgas,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Cadastrar Promoção - Combustível',
    exact: true
  },
  {
    // path: '/promocoes/editar/:id',
    path: '/regras/promocoes/editar/por-combustivel/:id',
    name: 'Editar Promoção - Combustível',
    component: createPromotionsByProductgas,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Editar Promoção - Combustível',
    exact: true
  },
  {
    // path: '/promocoes/cadastrar',
    path: '/regras/promocoes/cadastrar/por-produtos',
    name: 'Cadastrar Promoção - Produtos',
    component: createPromotionsByProductCategory,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Cadastrar Promoção - Produtos',
    exact: true
  },
  {
    // path: '/promocoes/editar/:id',
    path: '/regras/promocoes/editar/por-produtos/:id',
    name: 'Editar Promoção - Produtos',
    component: createPromotionsByProductCategory,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Editar Promoção - Produtos',
    exact: true
  },
  {
    // path: '/promocoes/cadastrar',
    path: '/regras/promocoes/cadastrar/por-servicos',
    name: 'Cadastrar Promoção - Serviços',
    component: createPromotionsByService,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Cadastrar Promoção - Serviços',
    exact: true
  },
  {
    // path: '/promocoes/editar/:id',
    path: '/regras/promocoes/editar/por-servicos/:id',
    name: 'Editar Promoção - Serviços',
    component: createPromotionsByService,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Editar Promoção - Serviços',
    exact: true
  },
  {
    // path: '/promocoes/editar-acumulo/:id',
    path: '/regras/promocoes/ajustar-acumulos/:id',
    name: 'Ajustar Acúmulos',
    component: editPromotions,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Ajustar Acúmulos'
  },
  {
    // path: '/promocoes',
    path: '/regras/promocoes',
    name: 'Promoções',
    component: listPromotions,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Promoções',
    exact: true
  },

  {
    path: '/configuracoes',
    name: 'Configurações',
    component: configurations,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Configurações',
    exact: true
  },

  // {
  //   path: '/reportAbastecimentos',
  //   name: 'Posto',
  //   component: reportFill,
  //   route: PrivateRoute,
  //   roles: ['Admin', 'customer'],
  //   title: 'Abastecimentos',
  // },
  {
    path: '/relatorios/clube-certo',
    name: 'Relatório de clientes inscritos no Clube Certo.',
    component: clientsSubscribedInClubeCerto,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Relatório de clientes inscritos no Clube Certo.',
    exact: true
  },
  {
    // path: '/relatorio-de-avaliacoes',
    path: '/relatorios/avaliacoes',
    name: 'Relatório de Avaliações',
    component: reportEvaluetions,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Relatório de Avaliações',
    exact: true
  },
  {
    // path: '/relatorio-de-vouchers/acumulados',
    path: '/relatorios/vouchers/acumulados',
    name: 'Relatório de Vouchers Acumulados',
    component: reportVouchersAcumulados,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Relatório de Vouchers Acumulados',
    exact: true
  },

  {
    // path: '/relatorio-de-vouchers/resgatados',
    path: '/relatorios/vouchers/resgatados',
    name: 'Relatório de Vouchers Resgatados',
    component: reportVouchersResgatados,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Relatório de Vouchers Resgatados',
    exact: true
  },
  {
    // path: '/relatorio-de-vouchers/resgatados',
    path: '/relatorios/vouchers/nao-utilizados',
    name: 'Relatório de Vouchers Não Utilizados',
    component: reportVouchersNaoUsados,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Relatório de Vouchers Não Utilizados',
    exact: true
  },
  {
    path: '/relatorios/vouchers/pendentes',
    name: 'Relatório de Vouchers Pendentes',
    component: reportVouchersPendentes,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Relatório de Vouchers Pendentes',
    exact: true
  },
  {
    // path: '/relatorio-de-clientes',
    path: '/relatorios/clientes',
    name: 'Relatório de Clientes',
    component: reportClients,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Relatório de Clientes',
    exact: true
  },

  {
    // path: '/relatorio-de-empresas/extrato',
    path: '/relatorios/empresas/extrato',
    name: 'Extrato de Empresas',
    component: reportEmpresas,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Extrato de Empresas',
    exact: true
  },
  {
    // path: '/relatorio-de-promocoes/performance',
    path: '/relatorios/promocoes/performance',
    name: 'Performance de promoções',
    component: ReportPerformancePromotions,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Performance de promoções',
    exact: true
  },
  {
    // path: '/relatorio-de-aniversariantes',
    path: '/relatorios/aniversariantes',
    name: 'Relatório de Aniversariantes',
    component: reportClientsBirthday,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Relatório de Aniversariantes',
    exact: true
  },
  {
    path: '/relatorios/indique-e-ganhe/extrato',
    name: 'Relatório de Indique e Ganhe',
    component: reportCredits,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Relatório de Indique e Ganhe',
    exact: true
  },
  {
    path: '/relatorios/credito-externos',
    name: 'Relatório Crédito de Terceiro',
    component: reportCreditExternal,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Relatório Crédito de Terceiro',
    exact: true
  },
  {
    path: '/relatorios/indique-e-ganhe/ranking',
    name: 'Raking Indique e Ganhe',
    component: ReportIndicateRanking,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Raking Indique e Ganhe',
    exact: true
  },
  {
    path: '/relatorios/atendentes/nota-avaliacao',
    name: 'Nota de Avaliação Atendentes',
    component: AverageRatingAttendants,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Nota de Avaliação Atendentes',
    exact: true
  },
  {
    path: '/relatorios/atendentes/ranking',
    name: 'Ranking de frentista',
    component: RankingAttendants,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Ranking de frentista',
    exact: true
  },
  {
    path: '/relatorios/atendentes/transacoes-por-atendente',
    name: 'Transações por Atendente',
    component: TransacionsByAttendant,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Transações por Atendente',
    exact: true
  },
  {
    path: '/clientes',
    name: 'Clientes',
    component: Clients,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Clientes',
    exact: true
  },
  {
    path: '/perfil-de-cliente',
    name: 'Perfil de Cliente',
    component: ClientProfiles,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Perfil de Cliente',
    exact: true
  },
  {
    path: '/perfil-de-cliente/cadastrar',
    name: 'Cadastrar Perfil de Cliente',
    component: CreateClientProfile,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Cadastrar Perfil de Cliente',
    exact: true
  },
  {
    path: '/perfil-de-cliente/editar/:id',
    name: 'Editar Perfil de Cliente',
    component: CreateClientProfile,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Editar Perfil de Cliente',
    exact: true
  },
  {
    path: '/clientes/indicadores/:id',
    name: 'Indicadores do Cliente',
    component: ClientDashboard,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Indicadores do Cliente',
    exact: true
  },
  {
    // path: '/relatorio-de-clientes/extrato',
    path: '/relatorios/clientes/extrato',
    name: 'Extrato dos Clientes',
    component: ExtratoCliente,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Extrato dos Clientes',
    exact: true
  },
  {
    // path: '/relatorio-de-clientes/extrato/:id',
    path: '/relatorios/clientes/extrato/:id',
    name: 'Extrato do Cliente',
    component: ExtratoCliente,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Extrato do Cliente',
    exact: true
  },
  {
    // path: '/relatorio-de-clientes/extrato',
    path: '/relatorios/clientes/tempo-de-compra',
    name: 'Tempo de Compra',
    component: ReportTimeSinceSale,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Tempo de Compra',
    exact: true
  },
  {
    path: '/relatorios/clientes/saldo-de-clientes',
    name: 'Saldo de Clientes',
    component: ReportClientsBalance,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Saldo de Clientes',
    exact: true
  },
  {
    // path: '/relatorio-de-clientes/veiculos',
    path: '/relatorios/clientes/veiculos',
    name: 'Veículos de Clientes',
    component: VeiculosCliente,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Veículos de Clientes',
    exact: true
  },
  {
    // path: '/relatorio-de-clientes/veiculos/:id',
    path: '/relatorios/clientes/veiculos/:id',
    name: 'Veículos de Clientes',
    component: VeiculosCliente,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Veículos de Clientes',
    exact: true
  },
  {
    // path: '/relatorio-tomada-decisao/decisao/:id',
    path: '/relatorios/clientes/decisao',
    name: 'Tomada de decisão',
    component: reportDecisionMaking,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Tomada de Decisão',
    exact: true
  },
  {
    path: '/relatorios/clientes/ranking-de-clientes',
    name: 'Ranking de Clientes',
    component: RankingClientes,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Ranking de Clientes',
    exact: true
  },
  {
    path: '/relatorios/vendas/por-produtos',
    name: 'Vendas por Produtos',
    component: reportsSalesByProducts,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'empresas', 'reports'],
    title: 'Vendas por Produtos',
    exact: true
  },
  {
    path: '/relatorios/vendas/convenios',
    name: 'Relatório de convênios',
    component: reportAgreements,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'reports'],
    title: 'Relatório de convênios',
    exact: true
  },
  {
    path: '/relatorios/vendas/projecao-de-vendas',
    name: 'Projeção de Vendas',
    component: reportsSalesProjection,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'empresas', 'reports'],
    title: 'Projeção de Vendas',
    exact: true
  },
  {
    path: '/relatorios/vendas/por-formas-de-pagamento',
    name: 'Vendas por Formas de Pagamento',
    component: reportsSalesByPayments,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor', 'empresas', 'reports'],
    title: 'Vendas por Formas de Pagamento',
    exact: true
  },
  {
    path: '/consultas/vouchers',
    name: 'Vouchers',
    component: BuscarVouchers,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Consulta Vouchers',
    exact: true
  },
  {
    path: '/consultas/abastecimentos',
    name: 'Abastecimentos',
    component: ConsultaAbastecimentos,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Consulta Abastecimentos',
    exact: true
  },
  {
    // path: '/banners',
    path: '/cadastros/banners',
    name: 'Banners',
    component: listBanners,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Banners',
    exact: true
  },
  {
    // path: '/banners/cadastrar',
    path: '/cadastros/banners/cadastrar',
    name: 'Cadastrar Banner',
    component: bannerCadastrar,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Cadastrar Banner',
    exact: true
  },
  {
    // path: '/banners/editar/:id',
    path: '/cadastros/banners/editar/:id',
    name: 'Editar Banner',
    component: bannerCadastrar,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Editar Banner',
    exact: true
  },
  {
    path: '/atendentes',
    name: 'Atendentes',
    component: listAtendentes,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Atendentes',
    exact: true
  },
  {
    path: '/atendentes/cadastrar',
    name: 'Cadastrar Atendente',
    component: createAtendentes,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Cadastrar Atendente',
    exact: true
  },
  {
    path: '/atendentes/editar/:id',
    name: 'Editar Atendente',
    component: createAtendentes,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Editar Atendente',
    exact: true
  },
  {
    path: '/atendentes/dashboard',
    name: 'Dashboard',
    component: dashboardAtendentes,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Dashboard',
    exact: true
  },
  {
    // path: "/turnos",
    path: '/atendentes/turnos',
    name: 'Turnos',
    component: listTurnos,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Turnos',
    exact: true
  },
  {
    // path: "/turnos/cadastrar",
    path: '/atendentes/turnos/cadastrar',
    name: 'Cadastrar Turno',
    component: createTurno,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Cadastrar Turno',
    exact: true
  },
  {
    // path: "/turnos/editar/:id",
    path: '/atendentes/turnos/editar/:id',
    name: 'Editar Turno',
    component: createTurno,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Editar Turno',
    exact: true
  },
  {
    path: '/atendentes/alterar-senha/:id',
    name: 'Alterar Senha',
    component: alterarSenhaAtendente,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Alterar Senha',
    exact: true
  },
  {
    // path: "/parametros-de-comissao",
    path: '/atendentes/parametros-de-comissao',
    name: 'Parâmetros de Comissões',
    component: listParametrosComissoes,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Parâmetros de Comissôes',
    exact: true
  },
  {
    // path: "/parametros-de-comissao/cadastrar",
    path: '/atendentes/parametros-de-comissao/cadastrar',
    name: 'Cadastrar Parâmetro de Comissão',
    component: createParametroComissao,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Cadastrar Parâmetro de Comissão',
    exact: true
  },
  {
    // path: "/parametros-de-comissao/editar/:id",
    path: '/atendentes/parametros-de-comissao/editar/:id',
    name: 'Editar Parâmetro de Comissão',
    component: createParametroComissao,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Editar Parâmetro de Comissão',
    exact: true
  },
  {
    // path: "/comissoes/calcular",
    path: '/atendentes/comissoes/calcular',
    name: 'Calcular Comissão',
    component: calculateComissao,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Calcular Comissão',
    exact: true
  },
  {
    // path: "/comissoes/detalhes/:id",
    path: '/atendentes/comissoes/detalhes/:id',
    name: 'Detalhes da Comissão',
    component: calculateComissao,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Detalhes da Comissão',
    exact: true
  },
  {
    // path: "/comissoes",
    path: '/atendentes/comissoes',
    name: 'Comissões',
    component: listComissoes,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Comissões',
    exact: true
  },
  {
    // path: "/metas",
    path: '/atendentes/metas',
    name: 'Metas',
    component: listMetas,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Metas',
    exact: true
  },
  {
    // path: "/metas/cadastrar",
    path: '/atendentes/metas/cadastrar',
    name: 'Cadastrar Meta',
    component: createMeta,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Cadastrar Meta',
    exact: true
  },
  {
    // path: "/metas/editar/:id",
    path: '/atendentes/metas/editar/:id',
    name: 'Editar Meta',
    component: createMeta,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Editar Meta',
    exact: true
  },
  {
    // path: "/faixas-de-metas",
    path: '/atendentes/faixas-de-metas',
    name: 'Faixas de Metas',
    component: listFaixaMetas,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Faixas de Metas',
    exact: true
  },
  {
    // path: "/faixas-de-metas/cadastrar",
    path: '/atendentes/faixas-de-metas/cadastrar',
    name: 'Cadastrar Faixa de Meta',
    component: createFaixaMeta,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Cadastrar Faixa de Meta',
    exact: true
  },
  {
    // path: "/faixas-de-metas/informacoes/:id",
    path: '/atendentes/faixas-de-metas/informacoes/:id',
    name: 'Informações da Faixa de Meta',
    component: createFaixaMeta,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Informações da Faixa de Meta',
    exact: true
  },
  {
    path: '/marketing/sms/selecao-de-clientes-v2',
    name: 'SMS',
    component: clientsSelectionV2,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'SMS',
    exact: true
  },
  {
    path: '/marketing/sms/selecao-de-clientes-v2/enviar/',
    name: 'Enviar SMS',
    component: createNotificationV2,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'Enviar SMS',
    exact: true
  },
  //{
  // path: '/marketing/sms/selecao-de-clientes-V2',
  // name: 'SMS',
  // component: clientsSelectionV2,
  // route: PrivateRoute,
  // roles: ['Admin', 'empresas', 'customer', 'distributor'],
  // title: 'SMS',
  // exact: true,
  //},
  //{
  // path: '/marketing/sms/selecao-de-clientes-V2/enviar',
  // name: 'Enviar SMS',
  // component: createNotificationV2,
  // route: PrivateRoute,
  // roles: ['Admin', 'empresas', 'customer', 'distributor'],
  // title: 'Enviar SMS',
  // exact: true,
  //},
  {
    path: '/marketing/whatsapp/selecao-de-clientes-v2',
    name: 'WhatsApp',
    component: clientsSelectionV2,
    route: PrivateRoute,
    roles: ['Admin', 'empresas', 'customer', 'distributor'],
    title: 'WhatsApp',
    exact: true
  },
  {
    // path: '/notificacoes/enviar',
    path: '/marketing/whatsapp/selecao-de-clientes-v2/enviar/',
    name: 'Enviar WhatsApp',
    component: createNotificationV2,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Enviar WhatsApp',
    exact: true
  },
  {
    path: '/distribuidores',
    name: 'Distribuidores',
    component: listDistribuidores,
    route: PrivateRoute,
    roles: ['Admin'],
    title: 'Distribuidores',
    exact: true
  },
  {
    path: '/distribuidores/cadastrar',
    name: 'Cadastrar Distribuidor',
    component: createDistribuidores,
    route: PrivateRoute,
    roles: ['Admin'],
    title: 'Cadastrar Distribuidor',
    exact: true
  },
  {
    path: '/distribuidores/editar/:id',
    name: 'Editar Distribuidor',
    component: createDistribuidores,
    route: PrivateRoute,
    roles: ['Admin'],
    title: 'Editar Distribuidor',
    exact: true
  },
  {
    path: '/acumulo-manual',
    name: 'Acúmulo Manual',
    component: listAcumuloManual,
    route: PrivateRoute,
    roles: ['Admin', 'customer'],
    title: 'Acúmulo Manual',
    exact: true
  },
  {
    path: '/estornar-resgate',
    name: 'Estornar Resgate',
    component: listEstornoResgate,
    route: PrivateRoute,
    roles: ['Admin'],
    title: 'Estornar Resgate',
    exact: true
  },
  {
    path: '/senhas-de-acesso',
    name: 'Senhas de Acesso',
    component: AccessKeys,
    route: PrivateRoute,
    roles: ['Admin'],
    title: 'Senhas de Acesso',
    exact: true
  },
  {
    path: '/acumulo-manual/inserir',
    name: 'Inserir Acúmulo Manual',
    component: createAcumuloManual,
    route: PrivateRoute,
    roles: ['Admin', 'customer'],
    title: 'Inserir Acúmulo Manual',
    exact: true
  },
  {
    path: '/estornar-resgate/realizar',
    name: 'Realizar Estorno de Resgate',
    component: createAcumuloManual,
    route: PrivateRoute,
    roles: ['Admin'],
    title: 'Realizar Estorno de Resgate',
    exact: true
  },
  {
    path: '/central-de-notificacoes',
    name: 'Central de Notificações',
    component: notificationCenter,
    route: PrivateRoute,
    roles: ['customer', 'Admin'],
    title: 'Central de Notificações'
  },
  {
    path: '/auditoria/espiao',
    name: 'Espião',
    component: spy,
    route: PrivateRoute,
    roles: ['customer', 'Admin'],
    title: 'Espião'
  },
  {
    path: '/auditoria/logs-do-sistema',
    name: 'Logs do Sistema',
    component: logs,
    route: PrivateRoute,
    roles: ['customer', 'Admin'],
    title: 'Logs do Sistema'
  },
  {
    path: '/auditoria/logs-de-requisicao',
    name: 'Logs de Requisições',
    component: requestLogs,
    route: PrivateRoute,
    roles: ['customer', 'Admin'],
    title: 'Logs de Requisições'
  },

  {
    path: '/auditoria/logs-de-precos',
    name: 'Logs de Preços',
    component: priceLogs,
    route: PrivateRoute,
    roles: ['customer', 'Admin'],
    title: 'Logs de Preços'
  },
  {
    path: '/gamificacao/classificacao',
    name: 'Níveis da Gamificação',
    component: listGamificationLevels,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Níveis da Gamificação',
    exact: true
  },
  {
    path: '/gamificacao/classificacao/cadastrar',
    name: 'Criar Nível da Gamificação',
    component: createGamificationLevels,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Criar Nível da Gamificação',
    exact: true
  },
  {
    path: '/gamificacao/classificacao/editar/:id',
    name: 'Editar Nível da Gamificação',
    component: createGamificationLevels,
    route: PrivateRoute,
    roles: ['Admin', 'customer', 'distributor'],
    title: 'Editar Nível da Gamificação',
    exact: true
  },
  {
    path: '/',
    exact: true,
    component: () => <Redirect to='/dashboard' />,
    route: PrivateRoute
  }
];

export { routes, PrivateRoute };
