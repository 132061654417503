import { gql } from '@apollo/client';

export const EXPORT_CLIENTS = gql`
  query clientsExport(
    $customer: ID!
    $dateStart: Date
    $dateEnd: Date
    $isAgreed: Boolean
    $isBlocked: Boolean
    $genders: [String]
    $profiles: [ID]
    $name: String
    $phone: String
    $email: String
  ) {
    exportClients(
      customer: $customer
      dateStart: $dateStart
      dateEnd: $dateEnd
      isAgreed: $isAgreed
      isBlocked: $isBlocked
      genders: $genders
      profiles: $profiles
      name: $name
      phone: $phone
      email: $email
    ) {
      name
      active
      sexo
      profile {
        name
      }
      credit
      debit
      email
      phone
      birthday
      registrationTime
      bonus
      authenticatedPhone
    }
  }
`;

export const PAGINATE_CLIENTS = gql`
  query paginateClients(
    $page: Int!
    $perPage: Int!
    $orderBy: String
    $orderDirection: ORDER_DIRECTION
    $search: String
    $searchFields: [String]
    $customer: ID!
    $dateStart: Date
    $dateEnd: Date
    $isAgreed: Boolean
    $isBlocked: Boolean
    $genders: [String]
    $profiles: [ID]
    $name: String
    $phone: String
    $email: String
    $cpf: String
    $isAuthenticated: Boolean
  ) {
    clientsPage(
      page: $page
      perPage: $perPage
      orderBy: $orderBy
      orderDirection: $orderDirection
      search: $search
      searchFields: $searchFields
      customer: $customer
      dateStart: $dateStart
      dateEnd: $dateEnd
      isAgreed: $isAgreed
      isBlocked: $isBlocked
      genders: $genders
      profiles: $profiles
      name: $name
      phone: $phone
      email: $email
      cpf: $cpf
      isAuthenticated: $isAuthenticated
    ) {
      totalDocs
      page
      totalPages
      docs {
        _id
        name
        phone
        email
        sexo
        birthday
        age
        dateLastBuy
        lastBuy
        dateFirstBuy
        createdAt
        buyFrequencyDays
        blocked
        authenticatedPhone
        agreed {
          _id
          name
          __typename
        }
        profile {
          _id
          name
          isDefaultProfile
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const GET_CLIENTS_FOR_NOTIFICATION = gql`
  query getClientsForNotification($customer: ID!) {
    clients(customer: $customer) {
      _id
      name
      email
      birthday
      phone
      sexo
      createdAt
      dateLastBuy
      lastBuy
      age
      blocked
      resumeSupply {
        productgas {
          _id
          name
        }
        gasstation {
          _id
          name
        }
      }
    }
  }
`;

export const GET_CLIENTS_DROPDOWN = gql`
  query getClientsDropdown($customer: ID!) {
    clients(customer: $customer) {
      _id
      name
    }
  }
`;

export const GET_CLIENTS_PAGE = gql`
  query ClientsPage(
    $page: Int!
    $perPage: Int!
    $customer: ID!
    $searchFields: [String]
    $search: String
    $name: String
  ) {
    clientsPage(
      page: $page
      perPage: $perPage
      customer: $customer
      searchFields: $searchFields
      search: $search
      name: $name
    ) {
      docs {
        name
        _id
      }
      totalDocs
      page
      totalPages
    }
  }
`;

export const GET_REGISTERED_CLIENTS = gql`
  query getRegisteredClients($customer: ID!, $dateStart: Date, $dateEnd: Date) {
    registeredClients(
      customer: $customer
      dateStart: $dateStart
      dateEnd: $dateEnd
    ) {
      createdAt
      count
    }
  }
`;

export const GET_CLIENT = gql`
  query getClient($id: ID!) {
    client(_id: $id) {
      _id
      name
      phone
      email
      sexo
      birthday
      age
      dateLastBuy
      lastBuy
      dateFirstBuy
      createdAt
      buyFrequencyDays
      cpf
      platform
      profile {
        _id
        name
        isDefaultProfile
        limit_vouchers_a_day
        limit_vouchers_r_day
        limit_vouchers_a_30_day
        limit_vouchers_r_30_day
        allow_vouchers_a_and_r_day
        limit_reais_a_day
        limit_reais_a_month
        updatedAt
        userUpdate {
          _id
          name
        }
      }
      agreed {
        _id
        name
        agreement {
          _id
          name
        }
      }
      car {
        _id
        placa
        ano
        combustivel
        seguro
        kmatual
        client {
          _id
        }
        maintenance {
          _id
          date
          description
          value
          dateNext
          typeMaintenance {
            name
          }
        }
      }
    }
  }
`;

export const GET_CLIENTS_CUSTOMER_LASTBUY = gql`
  query getClientsCustomerLast(
    $customer: ID!
    $dateStart: Date
    $dateEnd: Date
  ) {
    clients(customer: $customer, dateStart: $dateStart, dateEnd: $dateEnd) {
      lastBuy
      dateLastBuy
    }
  }
`;

export const GET_CHARTS_LASTBUY = gql`
  query getClientLastBuyChart($customer: ID!) {
    ClientTempoCompraChart(customer: $customer) {
      tempoCompra
      total
    }
  }
`;

//LastBuy
export const PAGINATE_LAST_BUY = gql`
  query paginateLastBuy(
    $customer: ID!
    $page: Int!
    $perPage: Int!
    $orderBy: String
    $orderDirection: ORDER_DIRECTION
    $search: String
    $searchFields: [String]
    $isPaginate: Boolean
    $boughtRange: [ClientBoughtSaleRange]
  ) {
    clientsLastBuy(
      customer: $customer
      page: $page
      perPage: $perPage
      orderBy: $orderBy
      orderDirection: $orderDirection
      search: $search
      searchFields: $searchFields
      isPaginate: $isPaginate
      boughtRange: $boughtRange
    ) {
      totalDocs
      page
      totalDocs
      docs {
        _id
        clientName
        phone
        mail
        lastBuy
      }
    }
  }
`;

export const GET_CLIENTS_CUSTOMER = gql`
  query getClientsCustomer($customer: ID!, $dateStart: Date, $dateEnd: Date) {
    clients(customer: $customer, dateStart: $dateStart, dateEnd: $dateEnd) {
      _id
      name
      phone
      sexo
      email
      age
      birthday
      lastBuy
      dateLastBuy
      credit
      debit
      platform
      blocked
      profile {
        _id
        name
      }
      car {
        _id
        placa
        ano
        combustivel
        seguro
        kmatual
        client {
          _id
        }
        maintenance {
          _id
          date
          description
          value
          dateNext
          typeMaintenance {
            name
          }
        }
      }
    }
  }
`;

export const GET_DOWNLOADS = gql`
  query getDownloads($customer: ID!) {
    downloadsClient(customer: $customer) {
      DownloadIos
      DownloadAndroid
    }
  }
`;

export const GET_CLIENTS_CUSTOMER_DASH = gql`
  query getClientsCustomerDash(
    $customer: ID!
    $dateStart: Date
    $dateEnd: Date
  ) {
    clients(customer: $customer, dateStart: $dateStart, dateEnd: $dateEnd) {
      _id
      sexo
      createdAt
      age
      birthday
      lastBuy
      platform
      dateLastBuy
    }
  }
`;

export const GET_CLIENTS_BALANCE_PAGINATE = gql`
  query getClientsBalancePaginate(
    $page: Int!
    $perPage: Int!
    $orderBy: String
    $orderDirection: ORDER_DIRECTION
    $search: String
    $searchFields: [String]
    $customer: ID!
  ) {
    clientsBalancePaginate(
      page: $page
      perPage: $perPage
      orderBy: $orderBy
      orderDirection: $orderDirection
      search: $search
      searchFields: $searchFields

      customer: $customer
    ) {
      totalDocs
      page
      totalPages
      docs {
        _id
        clientName
        reward
        claim
        debt
        balance
        totalBalance
      }
    }
  }
`;

export const GET_CLIENTS_BALANCE = gql`
  query getClientsBalance(
    $customer: ID!
    $dateStart: Date
    $dateEnd: Date
    $gasstation: [ID]
    $store: [ID]
    $ignoreEmptyClients: Boolean
  ) {
    clientsBalance(
      customer: $customer
      dateStart: $dateStart
      dateEnd: $dateEnd
      gasstation: $gasstation
      store: $store
      ignoreEmptyClients: $ignoreEmptyClients
    ) {
      _id
      name
      phone
      sexo
      email
      age
      birthday
      lastBuy
      dateLastBuy
      credit
      debit
      bonus
    }
  }
`;

export const UPDATE_CLIENT_PROFILE = gql`
  mutation updateClientProfile($_id: ID!, $profile: ID!) {
    updateClientProfile(_id: $_id, profile: $profile) {
      success
      message
    }
  }
`;

export const UPDATE_INVITE_CODE = gql`
  mutation updateInviteCode($_id: ID!, $inviteCode: String) {
    updateClientInviteCode(_id: $_id, inviteCode: $inviteCode) {
      success
      message
    }
  }
`;

export const REMOVE_CLIENT_PROFILE = gql`
  mutation removeClientProfile($_id: ID!) {
    removeClientProfile(_id: $_id) {
      success
      message
    }
  }
`;

export const BLOCK_CLIENT = gql`
  mutation blockClient($_id: ID) {
    blockClient(_id: $_id) {
      success
      message
    }
  }
`;

export const UNBLOCK_CLIENT = gql`
  mutation unblockClient($_id: ID) {
    unblockClient(_id: $_id) {
      success
      message
    }
  }
`;

export const UPDATE_CLIENT_INFO = gql`
  mutation updateClientInfo(
    $_id: ID!
    $name: String
    $phone: String
    $sex: String
  ) {
    updateClient(_id: $_id, name: $name, phone: $phone, sexo: $sex) {
      success
      message
    }
  }
`;

export const UPDATE_CLIENT_PASSWORD = gql`
  mutation updateClientPassword($_id: ID!, $password: String) {
    updateClient(_id: $_id, password: $password) {
      success
    }
  }
`;

export const UPDATE_CLIENT_MAIL = gql`
  mutation updateClientMail($_id: ID!, $email: String) {
    updateClientMail(_id: $_id, email: $email) {
      success
      message
    }
  }
`;

export const UPDATE_CLIENT_FIDELITY_NUMBER = gql`
  mutation updateClientFidelityNumber($_id: ID!, $cardFidelityNumber: String) {
    updateClient(_id: $_id, cardFidelityNumber: $cardFidelityNumber) {
      success
      message
    }
  }
`;

export const GET_SUSPECT_CLIENTS = gql`
  query getSuspectClients($customer: ID!) {
    clientsSuspect(customer: $customer) {
      _id
      name
      phone
      email
      isSuspect
      suspectList
      supply {
        _id
        voucher
        discount
      }
    }
  }
`;

export const GET_CLIENTS_FILTER_PAGE = gql`
  query getClientsFilterPage($inputs: ClientsFilterPageInput!) {
    clientsFilterPage(inputs: $inputs) {
      totalDocs
      page
      totalPages
      docs {
        _id
        name
        phone
        email
        sexo
        birthday
        age
        dateLastBuy
        lastBuy
        dateFirstBuy
        createdAt
        buyFrequencyDays
        blocked
      }
    }
  }
`;
